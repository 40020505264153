$primary: #e30011;
$secondary: #ffc53c;
$dark-blue:#29266e;
$food_app_light_red_color: #ffccb0;
$food_app_light_gray_color: #fffbf4;
$food_app_gray_border_color: #e6e6e6;
$food_text_color: #202838;
$food_gray_color: #6b707b;
$food_app_notification: #e45986;
$food_app_green: #3bc14a;
$food_app_border: #f0f0f0;
$food_app_bg: #f7f2ee;
$very-light-red: #fff9f9;
$light-red: #fff3e3;

// normal colors
$black: #000;
$white: #fff;
$unread: #d6ebff;
$gray: #e0dfdc;
$white-smoke: #f5f5f5;
$dark-gray: #8d8f94;
$success: #1a8d08;
$green: #73cb57;
$purple: #7e57c2;
$scroll-border: #d3d3d3;
$light-gray: #f2f2f2;
$default-box-shadow: 0 2px 15px 0 #dcdcdc;
$gradient-background: linear-gradient(to right, #f3b605 0%, #ff6b5e 100%);
$border-color: #dadce0;
$light-shade-gray: #ddd;
$alice-light-blue: #f4f7f9;
$caribbean-green: #026ec1;
$dark-charcoal: #333;
$chinese-silver: #ccc;
$scrollbar-color: #d3d3d3;
$white-smoke: #f5f5f5;