@use '../Style/colors.scss';

@mixin HeadingStyle {
  color: colors.$primary;
  font-size: 20px;
  margin-bottom: 14px;
  text-transform: uppercase;
}

@mixin fullWidth {
  width: 100%;
}

@mixin afterIcon {
  font-family: Flaticon;
  line-height: 12px;
  position: absolute;
  right: 0;
  top: 0;
  width: 13px;
}

@mixin cardDesign($background: colors.$white, $radius: 10px, $padding: 10px, $boxShadow: 0 2px 10px rgba(40, 44, 63, 0.15)) {
  background: $background;
  border-radius: $radius;
  padding: $padding;
  box-shadow: $boxShadow;
}

@mixin borderBox {
  padding: 17px;
  border: 1px solid colors.$food_app_gray_border_color;
  border-radius: 10px;
}

@mixin titleOverflowHide {
  -webkit-align-self: center;
  align-self: center;
  -webkit-box-flex: 1 1 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  padding-right: 12px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-calc(100% - 56px);
  width: calc(100% - 56px);
}

@mixin grayBorder {
  border: 1px solid colors.$border-color;
}

@mixin antInputFocus {
  border: 1px solid colors.$chinese-silver;
  box-shadow: none;
}

@mixin bacgroundImage($image, $position, $size, $repeat: no-repeat) {
  background-image: $image;
  background-position: $position;
  background-repeat: $repeat;
  background-size: $size;
}

@mixin flexDesign($display: flex, $alignment: center, $justify: space-between, $direction: row) {
  align-items: $alignment;
  display: $display;
  flex-direction: $direction;
  justify-content: $justify;
}

@mixin linearGradient($color1: colors.$black, $color2: rgba(0, 0, 0, 0), $color3: rgba(0, 0, 0, 0.8)) {
  background: $color1;
  background: linear-gradient(180deg, $color2 57%, $color3 98%);
}

/* stylelint-disable-next-line max-line-length */
@mixin roundButton($width: 47px, $height: 47px, $radius: 50%, $textAlign: center, $background: colors.$white, $display: flex, $alignment: center, $justify: center, $font: 22px, $border: 0) {
  align-items: $alignment;
  background: $background;
  border: $border;
  border-radius: $radius;
  display: $display;
  font-size: $font;
  height: $height;
  justify-content: $justify;
  text-align: $textAlign;
  width: $width;
}

@mixin imageFit($width: 100%, $height: auto, $fit: contain, $position: center center) {
  height: $height;
  object-fit: $fit;
  object-position: $position;
  width: $width;
}

@mixin alignCenter ($align: center, $justify: center) {
  align-items: $align;
  display: flex;
  justify-content: $justify;
}

@mixin radiusLR($left: 20px, $right: 20px) {
  border-top-left-radius: $left;
  border-top-right-radius: $right;
}

@mixin gridContent($size: 16%) {
  display: grid;
  -ms-grid-columns: (minmax($size, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}


@mixin textStroke($size: 45px, $width: 1.5px, $color: colors.$primary, $fillColor: transparent) {
  font-size: $size;
  -webkit-text-stroke-width: $width;
  -webkit-text-stroke-color: $color;
  -webkit-text-fill-color: $fillColor;
}

@mixin listStyle {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin cursorPointer {
  cursor: pointer;
}

@mixin circleProfile($background: colors.$primary, $color: colors.$white, $width: 33px, $height: 33px, $display: inline-block) {
  background: $background;
  color: $color;
  width: $width;
  height: $height;
  display: $display;
  text-align: center;
  line-height: 2;
  border-radius: 100%;
}

@mixin roundedBorder($color: colors.$food_app_gray_border_color, $padding: 7px 10px, $radius: 6px) {
  border: 1px solid $color;
  padding: $padding;
  border-radius: $radius;
}

@mixin spacing($display: inline-block, $margin: 5px) {
  display: $display;
  margin-right: $margin;
}


// before content ======

@mixin beforeContent($width: 100%, $height: 100%, $left: 0, $top: 0, $bottom: unset, $right: unset, $background: linear-gradient(137deg, colors.$primary, #235183), $opacity: 0.7, $radius: 10px) {
  content: "";
  position: absolute;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
  width: $width;
  height: $height;
  background: $background;
  opacity: $opacity;
  border-radius: $radius ;
}

@mixin backgroundRepeat($repeat: no-repeat, $size: contain) {
  background-repeat: $repeat;
  background-size: $size;
}

@mixin minheight {
  height: calc(100vh - 160px);
  overflow-y: auto;
}