@use "./mixin.scss" as mixin;
@use './colors.scss';
@use './fonts.scss';

@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Great+Vibes&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// :root {
//   --primary: #e30011;

//   // --secondary: #ffb049;
//   --secondary: #ffc53c;
//   --food_app_light_red_color: #ffccb0;
//   --food_app_light_gray_color: #fffbf4;
//   --food_app_gray_border_color: #e6e6e6;
//   --food_text_color: #202838;
//   --food_gray_color: #6b707b;
//   --food_app_notification: #e45986;
//   --food_app_green: #3bc14a;
//   --food_app_border: #f0f0f0;
//   --food_app_bg: #f7f2ee;
//   --very-light-red: #fff9f9;
//   --light-red: #fff3e3;

//   // normal colors
//   --black: #000;
//   --white: #fff;
//   --unread: #d6ebff;
//   --gray: #e0dfdc;
//   --white-smoke: #f5f5f5;
//   --dark-gray: #8d8f94;
//   --success: #1a8d08;
//   --green: #73cb57;
//   --purple: #7e57c2;
//   --scroll-border: #d3d3d3;
//   --light-gray: #f2f2f2;
//   --default-box-shadow: 0 2px 15px 0 #dcdcdc;
//   --gradient-background: linear-gradient(to right, #f3b605 0%, #ff6b5e 100%);
//   --padding-desktop: 60px 0;
//   --border-color: #dadce0;
//   --light-shade-gray: #ddd;
//   --alice-light-blue: #f4f7f9;
//   --caribbean-green: #026ec1;
//   --dark-charcoal: #333;
//   --chinese-silver: #ccc;
//   --scrollbar-color: #d3d3d3;
//   --white-smoke: #f5f5f5;
// }


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// webkit scrollbar starts

::-webkit-scrollbar-track {
  background-color: colors.$white-smoke;
  border-radius: 10px;

  // -webkit-box-shadow: inset 0 0 6px colors.$white-smoke;
}

::-webkit-scrollbar {
  background-color: colors.$white-smoke;
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: colors.$scrollbar-color;
  border-radius: 10px;
}

// webkit scrollbar ends

body {
  background: colors.$food_app_bg;
  font-family: fonts.$roboto;
  font-size: 16px;
}
a,
a:hover {
  text-decoration: none;
}
.lead{
  font-size: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: fonts.$crimson;
  font-weight: 600;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

hr {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  box-sizing: content-box;
  height: 0;
  margin: 16px 0;
  overflow: visible;
}

.custom-loader-back-active {
  height: 100vh !important;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483647;
}

// font-family

.roboto {
  font-family: fonts.$roboto;
}

.great-vibes {
  font-family: fonts.$great-vibes;
}

.crimson {
  font-family: fonts.$crimson;
}

// =============================
// loading-content==============
.loading-content {
   @include mixin.flexDesign($justify: center, $alignment: center);

  min-height: 100vh;
  overflow-y: hidden;
  text-align: center;

  img {
    width: 150px;
  }
}

// align-center===============
.align-center {
   @include mixin.alignCenter;
}

.align-left {
   @include mixin.alignCenter($justify: starts);
}

// container ==========
.container {
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;

  @media (max-width: 1000px) {
    width: 97%;
  }
}

// styles parts grid and flex ==========
.flex-design {
   @include mixin.flexDesign;
}

.flexdesign-spacebetween {
   @include mixin.flexDesign;
}

// grid design ==========
.display-grid-73 {
  display: grid;
  grid-template-columns: 70% 30%;

  @media (max-width: 800px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
}

.d-grid {
  display: grid;
}

// image alignment ==========
.img-align-center {
  display: block;
  margin: 0 auto;
}

// background gradient ==========
.black-linear-gradient {
   @include mixin.linearGradient;
}

.red-linear-gradient {
   @include mixin.linearGradient($color1: colors.$primary, $color2: rgba(0, 0, 0, 0.2), $color3: rgba(0, 0, 0, 0.4));
}

.yellow-linear-gradient {
   @include mixin.linearGradient($color1: colors.$secondary, $color2: rgba(253, 236, 220, 0.35), $color3: rgba(253, 236, 220, 99.2));
}

.gradient-section {
   @include mixin.linearGradient($color1: colors.$white, $color2: rgba(255, 255, 255, 0.35), $color3: rgba(239, 241, 242, 0.81));
}

// display grid ==========
.display-grid {
   @include mixin.gridContent($size: 50%);

  align-items: center;
  min-height: 100vh;

  @media (max-width: 650px) {
    display: block;
  }
}

// text colors ==========
.fap-text-color,
.text-black {
  color: colors.$food_text_color;
}

.text-primary {
  color: colors.$primary !important;
}

.text-yellow {
  color: colors.$secondary !important;
}

.text-capitalize {
  text-transform: capitalize;
}

// custom-font-size
.custom-font-size {
  p {
    font-size: 1.2rem;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
}

// border-dashed
.border-top-dashed {
  border-top: 2px dashed #000;
}

.border-bottom-dashed {
  border-bottom: 2px dashed #000;
}

.border-bottom-solid {
  border-bottom: 2px solid #000;
}

// bold text =============

.text-bold {
  font-weight: 500;
}

.sub-title {
  font-size: 18px;
}

.paragraph {
  font-size: 14px;
}

// title text

.title-text {
  font-size: 2.4rem;
  line-height: 130%;

  @media (max-width: 800px) {
    font-size: 2rem;
    text-align: center;
  }
}

// spacing ==========

.spacing {
  padding: 4rem 0;
}

.top-spacing {
  padding-top: 4rem;
}

.bottom-spacing {
  padding-bottom: 4rem;
}

// divider styles
.divider-1 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/heading-divider1.png"), $position: left, $size: contain);

  height: 36px;
  width: 100%;

  @media (max-width: 575px) {
    background-position: center center;
  }
}

.divider-2 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/heading-divider2.png"), $position: center center, $size: contain);

  height: 28px;
  width: 100%;
}

.divider-3 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/banner_bottom_shape.png"), $position: unset, $size: cover, $repeat: repeat);

  height: 37px;
  width: 100%;
}

.divider-4 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/banner_bottom_shape-1.png"), $position: unset, $size: cover, $repeat: repeat);

  height: 37px;
  width: 100%;
}

.menu-page {
  .divider-3 {
     @include mixin.bacgroundImage($image: url("../assets/images/shape/banner_bottom_shape-1.png"), $position: unset, $size: cover, $repeat: repeat);

    height: 37px;
    width: 100%;
  }
}

// limit line height for text
.text-limit-lineheight {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

// before content sections
.section1 {
  position: relative;

  &::before {
     @include mixin.beforeContent($background: url("../assets/images/shape/content-shape3.png"), $width: 51px, $height: 130px, $opacity: 1, $radius: 0);
     @include mixin.backgroundRepeat($size: 100%);
  }

  &::after {
     @include mixin.beforeContent($background: url("../assets/images/shape/content-shape4.png"), $width: 50px, $height: 82px, $opacity: 1, $top: unset, $left: unset, $right: 0, $bottom: 0, $radius: 0);
     @include mixin.backgroundRepeat($size: 100%);
  }
}

.section2 {
  position: relative;

  &::before {
     @include mixin.beforeContent($background: url("../assets/images/shape/content-shape5.png"), $width: 90px, $height: 138px, $top: 20%, $opacity: 1, $radius: 0);
     @include mixin.backgroundRepeat($size: 100%);
  }

  &::after {
     @include mixin.beforeContent($background: url("../assets/images/shape/content-shape6.png"), $width: 90px, $height: 94px, $opacity: 1, $top: unset, $left: unset, $right: 0, $bottom: 20%, $radius: 0);
     @include mixin.backgroundRepeat($size: 100%);
  }
}

// background-patterns  ==========
.bg-lightred {
  background-color: colors.$light-red;
}

.bg-pattern1 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/pattern-bg.jpg"), $position: center center, $size: cover);
}
.bg-pattern-tomatto {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/tomatto-pattern.svg"), $position: center center, $size: contain);
}
.bg-pattern2 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/pattern-bg-2.jpg"), $position: center center, $size: cover);
}

.bg-pattern3 {
   @include mixin.bacgroundImage($image: url("../assets/images/shape/pattern-bg-3.jpg"), $position: center center, $size: cover);
}

// card ==========
.food-card {
  background: colors.$white;
  border-radius: 10px;
  padding: 13px;
}

.card-design {
   @include mixin.cardDesign;
}

.border-box {
   @include mixin.borderBox;
}

// height setup ==========
.min-height-100vh {
  min-height: 100vh;
}

.min-height-60vh {
  min-height: 66vh;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-bottom-120 {
  padding-bottom: 120px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

// custom-table

.custom-table {
  .table-image-container {
    background: colors.$white;
    border: 1px solid colors.$food_app_gray_border_color;
    border-radius: 6px;
    height: 43px;
    padding: 3px;
    width: 43px;

    img {
       @include mixin.imageFit($width: 37px, $height: 37px);
    }
  }
}

// hide sidebar ==================
.ant-layout-has-sider.app-sidebar {
  aside {
    display: none;
  }
}

// filter-layout ==================
.filter-layout {
  padding: 10px;

  .flex-design {
    margin-bottom: 20px;
  }

  .filter-section {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px;
  }
}

// table-layout ==================
.table-layout {
  .flex-design {
    margin-bottom: 20px;
  }
}

// page-not-found ================
.page-not-found {
  text-align: center;

  h1 {
    font-size: 24px;
  }

  img {
    width: 170px;
  }
}

// change-password ===============
.change-password {
  img {
    width: 40%;
  }
}

// no-orders =====================

.no-orders {
  background: colors.$white;
  border: 1px solid colors.$white;
  border-radius: 10px;
  padding: 30px;

  img {
    width: 200px;
  }
}

// Kitchen and Server View Common styles ==================
// =========================================================
// container
.admin-view {
  .food-app-container {
    // background-color: colors.$white;
    background-color: colors.$food_app_bg;
  }
}

.food-app-container {
  // background-color: colors.$white;
  background-color: colors.$food_app_bg;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  padding: 10px 20px;

  .custom-spacing {
    padding: 10px 20px 106px;
  }
}

.bottom-navigations {
   @include mixin.radiusLR;

  bottom: 0;
  box-shadow: 0 2px 10px #e3e3e3;
  left: 0;
  padding: 8px 23px 4px;
  position: fixed;
  width: 100%;

  .menu-items {
    width: 80px;
  }

  a {
    color: #909399;
    position: relative;
    text-align: center;

    .menu-items {
      width: 80px;
    }

    .title {
      font-size: 13px;
    }

    &:hover {
      color: colors.$primary;
    }

    &.menu-items.active {
      color: colors.$primary;

      //  @include mixin.roundButton($width: 56px, $height: 56px, $background: colors.$primary);
    }

    .count {
       @include mixin.alignCenter;

      background: colors.$food_app_notification;
      border-radius: 6px;
      color: colors.$white;
      font-size: 11px;
      height: 19px;
      left: 42px;
      position: absolute;
      top: -4px;
      width: 19px;
    }
  }

  @media (max-width: 850px) {
    visibility: visible;
  }
}

.pattern-section {
  background-size: 27%;
  padding: 32px 0;

  &.home-main-page {
     @include mixin.flexDesign;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
}

// profile section styles ========
.food-search,
.profile-and-notification {
  position: relative;
  z-index: 1;
}

.profile-and-notification {
   @include mixin.flexDesign;

  width: 79%;

  .profile {
     @include mixin.flexDesign;

    .profile-icon {
      margin-right: 12px;

      img {
        border: 3px solid colors.$white;
        border-radius: 50%;
        height: 63px;
        width: 63px;
      }
    }
  }

  .profile-content {
    h3 {
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 3px;
      text-transform: capitalize;
    }

    p {
      color: colors.$food_gray_color;
      font-size: 16px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 13px;
    width: 100%;
  }
}

.notification {
   @include mixin.roundButton($width: 53px, $height: 53px);

  .icon {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .count {
    background-color: colors.$food_app_notification;
    border-radius: 4px;
    color: colors.$white;
    font-size: 13px;
    padding: 1px 5px;
    position: absolute;
    right: 3px;
    top: 6px;
  }
}

// Food Search ========
.food-search {
  input[type="text"] {
    border-color: colors.$white;
    border-radius: 50px;
    color: #646464;
    font-size: 17px;
    min-height: 53px;
    padding: 0 30px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

// food-category-listings ========

.food-category-listings {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;

  .foods {
    display: block;
    margin-top: 10px;
    padding: 0 8px;

    .food-list {
      align-items: center;
      background: colors.$white;

      // border: 1px solid #e6e6e6;
      border-radius: 10px;
      box-shadow: 0 2px 10px #e3e3e3;
      display: grid;
      grid-template-columns: 87px 49% 22%;
      padding: 10px;
      position: relative;

      .food-image {
        border-radius: 10px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        height: 69px;
        overflow: hidden;
        width: 82px;

        img {
           @include mixin.imageFit($fit: cover, $height: 100%);
        }

        @media (max-width: 320px) {
          box-shadow: unset;
          height: 90px;
          width: 100%;
        }
      }

      .description {
        margin: 0 15px;

        h2 {
          font-size: 17px;
          line-height: 117%;

          @media (max-width: 500px) {
            font-size: 18px;
          }
        }

        @media (max-width: 320px) {
          margin: 10px 0 0;
          padding-right: 72px;
        }
      }

      .price {
        color: #9b9b9b;
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
      }

      .addto-cart {
        float: right;

        button {
          background: colors.$primary;
          border: 0;
          border-radius: 5px;
          color: colors.$white;

          &:hover,
          &:focus,
          &:active {
            background: colors.$primary;
            color: colors.$white;
          }
        }

        @media (max-width: 320px) {
          bottom: 29px;
          position: absolute;
          right: 19px;
        }
      }

      @media (max-width: 500px) {
        grid-template-columns: 87px 49% 22%;
      }

      @media (max-width: 320px) {
        grid-template-columns: auto;
      }
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  }

  .remove-from-cart {
    bottom: 10px;
    position: absolute;
    right: 10px;
    text-align: right;

    button {
      background: transparent;
      border: 0;
      color: colors.$primary;
      padding: 0;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 500px) {
    grid-template-columns: 100%;
  }
}

// Order confirmation screen ==========
.order-confirmation-notice {
  background: #dbfedd;
  border: 1px solid #63e169;
  border-radius: 20px;
  padding: 20px;
  text-align: center;

  h3 {
    color: colors.$food_app_green;
  }

  h3,
  h4 {
    margin-bottom: 4px;
  }

  img {
    width: 70%;
  }
}

// order-summary =============
.order-summary {
  margin-top: 20px;

  h2 {
    font-size: 18px;
  }

  .ordered-items {
    .order-list {
      align-items: center;
      border-bottom: 1px solid colors.$food_app_gray_border_color;
      display: grid;
      grid-template-columns: 96px 75%;
      margin-top: 10px;
      padding-bottom: 10px;
      position: relative;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .food-image {
        border-radius: 10px;

        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        height: 96px;
        overflow: hidden;
        width: 96px;

        img {
           @include mixin.imageFit($height: 100%, $fit: cover);
        }
      }

      .description {
        margin: 0 15px;

        h2 {
          font-size: 17px;

          @media (max-width: 500px) {
            font-size: 15px;
          }
        }

        .price {
          margin-right: 10px;
        }

        .price,
        .quantity {
          color: #9b9b9b;
          font-size: 14px;
          font-weight: normal;
          margin-top: 2px;

          span {
            margin-left: 3px;
          }
        }

        .flex-design {
          justify-content: flex-start;
        }

        .order-status {
          font-size: 12px;
          position: absolute;
          right: 0;
          top: 0;

          .current-status {
            border-radius: 20px;
            color: colors.$white;
            display: inline-block;
            margin-left: 5px;
            padding: 2px 7px;
          }

          .Open {
            background: colors.$food_app_green;
          }

          .Served {
            background: colors.$dark-gray;
          }

          .Cancelled {
            background: colors.$primary;
          }
        }
      }
    }
  }
}

// Dashboard Sscreen
.dashboard-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard-tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .dashboard-tile {
     @include mixin.cursorPointer;

    align-items: center;
    background: colors.$white;
    border: 1px solid colors.$food_app_gray_border_color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100px;
    justify-content: center;
    margin: 5px;
    width: 87px;
  }
}

// kitchen and server view ends here =========

// profile-information =======================
.profile-information {
  width: 100%;

  .profile {
    text-align: center;

    img {
      width: 100px;
    }
  }

  .details {
    margin-bottom: 10px;

    .name,
    .email {
      span {
        display: inline-block;
        font-weight: 500;
        margin-right: 5px;
      }
    }
  }

  .food-card {
    padding: 40px;

    @media (max-width: 500px) {
      padding: 30px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bill-preview {
  table td,
  th {
    vertical-align: top;
  }
}

// breadcrumb
.breadcrumb {
  height: auto;
  min-height: 55vh;
  position: relative;
  margin-bottom: 0;

  h1 {
    font-size: 4rem;

    @media (max-width: 900px) {
      font-size: 2.5rem;
    }
  }

  span {
    padding: 0 5px;
  }

  &.about-title-image {
     @include mixin.bacgroundImage($image: url("../assets/images/about-us-bg.jpg"), $position: center center, $size: cover);
  }

  &::before {
     @include mixin.beforeContent($background: linear-gradient(5deg, colors.$black, transparent), $opacity: 6, $radius: 0);
  }

  .nav-links {
    background: colors.$secondary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    bottom: 13px;
    display: inline-block;
    left: 10%;
    padding: 15px 20px;
    position: relative;
    position: absolute;
    width: auto;

    a {
      color: colors.$black;
    }
  }
}


// view-map ======
.view-map {
  font-size: 1.1rem;

  .details {
    min-height: 309px;

    @media (max-width: 767px) {
      min-height: auto;
    }
  }

  .phone {
    color: colors.$black;
    font-size: 1.7rem;
    font-weight: bold;

    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 767px) {
    &.p-5 {
      padding: 3rem 1rem !important;
    }
  }
}

.hyerlink-text {
  color: colors.$primary !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: colors.$primary !important;
}

.ant-radio-inner::after {
  background-color: colors.$primary !important;
}


// cta-section
.cta-section{
  h1, h2{
    font-size: 25px;
  }
  h3{
    font-size: 20px;
  }
}

// tag
.tag{
  padding: 5px 10px;
  background-color: #ffd6d9;
  font-size: 14px;
}