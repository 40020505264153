@use './mixin.scss' as mixin;
@use './colors.scss';

.ant-layout {
    background: transparent;
}


// button style ==========
a.ant-btn {
    padding-top: 0;
    line-height: 33px;
    padding-bottom: 0;
    border-radius: 4px;
}

.ant-btn {
    height: auto;
    min-height: auto;

    &.ant-btn-sm {
        min-height: 28px;
        font-size: 13px;

        svg {
            width: 15px;
        }
    }
}

.ant-btn-primary {
    background: colors.$primary;
    border-color: colors.$primary;
    color: colors.$white;

    &:hover,
    &:focus {
        background: colors.$primary;
        border-color: colors.$primary;
        color: colors.$white;
    }
}

// .ant-btn-secondary {
//     background: colors.$secondary;
//     border-color: colors.$secondary;
//     color: colors.$white;

//     &:hover,
//     &:focus {
//         background: colors.$secondary;
//         border-color: colors.$secondary;
//         color: colors.$white;
//     }
// }

.btn {
    &.glow {
        background: colors.$primary;
        border: 1px solid colors.$primary;
        outline: none;
        border-radius: 3px;
        color: colors.$white;
    }
}

.btn-large {
    padding: 6px 31px !important;
    font-size: 17px;
}

.white-btn {
    background: transparent !important;
    border-color: colors.$white;
    color: colors.$white;
    border-radius: 5px;
}

// input fields
 input, optgroup, select, textarea{
    padding: 9px 20px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
 }
 input:focus, optgroup:focus, select:focus, textarea:focus{
    border: 1px solid #bfbfbf;
    border-radius: 4px;
}
.ant-input-affix-wrapper {

    &:hover,
    &:focus,
    &:active {
        border-color: #d9d9d9;
    }

    &.ant-input-affix-wrapper-focused {
        box-shadow: unset;
    }
}

.ant-input {

    &:hover,
    &:focus,
    &:active {
        border-color: #d9d9d9;
        outline: none;
        box-shadow: none;
    }
}

// Ant Header
.ant-menu {
    background: transparent;
    box-shadow: none;

    .ant-menu-item {
        a {
            display: flex;
            align-items: center;

            svg {
                margin-right: 6px;
            }
        }
    }
}

.ant-layout-header {
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: colors.$white;
    padding: 0 20px;
    box-shadow: 0 15px 40px -20px rgba(40, 44, 63, .15);
}

// action-buttons style ========
.action-buttons {
    min-width: 100px;

    ul {
        @include mixin.listStyle;

        li {
            @include mixin.cursorPointer;
            padding: 3px 0;

            &:hover {
                color: colors.$primary
            }

            i {
                margin-right: 8px;
            }
        }
    }
}

// .ant-drawer-body
.ant-drawer-header {
    padding: 16px 15px;
}

.ant-drawer-body {
    padding: 15px;

    .increase-decrease-quantity {

        .cart-quantity {
            color: colors.$primary;
            margin-bottom: 0;

            &.centered-space-between {
                justify-content: center;
            }
        }

        label {
            font-weight: bold;
        }

        textarea {
            border-radius: 5px;
        }
    }

}

.no-button-style {
    padding: 0;
    border: 0;
    box-shadow: 0;
    background: transparent;
    min-height: 8px;
    font-size: 13px;
    line-height: 164%;
    text-align: left;
    width: auto;
    display: inline-block;

    svg {
        top: 3px;
        position: absolute;
    }

}

.transparent-btn {
    background: transparent;
    border: unset;
    font-size: 16px;
    box-shadow: unset;
}